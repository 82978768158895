export default function () {
    updateSvg();
    generateReadTime();
    window.addEventListener('resize', () => {
        updateSvg();
    });

    const sidebar = document.querySelector('.sidebar__wrapper');
    if (sidebar) {
        const viewportOffset = sidebar.getBoundingClientRect();
        const top = viewportOffset.top;
  
        window.addEventListener('scroll', () => {    
            const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            if (scrollTop >= top) {
                sidebar.classList.add('sidebar__wrapper--fixed');
            } else {
                sidebar.classList.remove('sidebar__wrapper--fixed');
            }
        });
    }
}

function updateSvg() {
    const width = window.innerWidth || document.documentElement.clientWidth ||
        document.body.clientWidth;
    const slashPath = document.querySelector('#articleSlash');

    if (width < 1200 && slashPath) {
        slashPath.setAttribute('d', 'm 1115 406 C 0.3 405 -0.3 404.1 0.1 403.2 l 0.9 -402.2 h 1111.5 l 3 405');
    } else if (slashPath) {
        slashPath.setAttribute('d', 'M 1317 405 H 1.2 c -0.9 0 -1.5 -0.9 -1.1 -1.8 L 202.4 0.7 c 0.2 -0.4 0.6 -0.7 1.1 -0.7 h 1111.5 l 3 405');
    }
}

function generateReadTime() {
    const readTimeElement = document.querySelector('.hero-article__read-time');
    if (readTimeElement) {
        const minutes = document.querySelector('body').innerText.split(/\s+/).length / 200;
        const readTime = Math.ceil(minutes);
        readTimeElement.innerText = readTime + ' ' + 'minute read'; 
    }
}