export default function () {
    const el = document.querySelector('.video');
    if (!el) {
        return;
    }
    const transcriptButton = document.querySelectorAll('.video__transcript-button');
    transcriptButton.forEach(button => {
        button.addEventListener('click', e => {
            const buttonText = button.querySelector('.video__transcript-button__text');
            if (buttonText.innerHTML === "Show transcription") {
                buttonText.innerHTML = "Hide transcription";
            } else {
                buttonText.innerHTML = "Show transcription";
            }
        });
    })
}