$(document).ready(function () {
  //Nav Toggle Mobile
  $('#js-toggle').click(function () {
    $('#js-hamburger').toggleClass('is-active');
    $('body').toggleClass('nav-open');
  });

  //Make nav not break on window resize
  $(window).on('resize', function (event) {
    var windowWidth = $(window).width();
    if (windowWidth > 1180) {
      $('#js-hamburger').removeClass('is-active');
      $('body').removeClass('nav-open');
    }
  });
});
