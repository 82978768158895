import './scss/main.scss';
import './js/load';
import articleHero from './js/article-hero';
import video from './js/video';
import cookieBanner from './js/cookie-banner';
import downloadForm from './js/download-form';

articleHero();
video();
downloadForm();
