export default function () {
    const button = document.getElementById('downloadFormTrigger');
    const form = document.getElementById('downloadForm');

    button.addEventListener('click', () => {
        form.style.maxHeight = form.scrollHeight + "px";
    })

    document.addEventListener("DOMContentLoaded", function(){
        if(document.querySelector('.sproutforms-message-success')) {
            const reportLink = document.getElementById('reportLink');
            reportLink.click();
            form.style.maxHeight = form.scrollHeight + "px";
        }
    });
}
